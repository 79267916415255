import { Button, Input, Text, Select } from '@geist-ui/core'
import { Form, message } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import isValidURL from '../../../utils/isValidURL'

const authentificationOptions = ['none', 'header']

const APIForm = ({ projectId, triggerOnUpload, closeDataSourceModal }) => {
  const [validateStatus, setValidateStatus] = useState(true)
  const [loading, setLoading] = useState(false)

  const [baseIdValue, setBaseIdValue] = useState('')
  const [authentification, setAuthentification] = useState(null)
  const [apiKey, setAPIKey] = useState('null')
  const [tableIdValue, setTableIdValue] = useState('')
  const [viewIdValue, setViewIdValue] = useState('')

  const onFinish = async values => {
    setLoading(true)
    const { apiURL, authentificationType, apiKey } = values

    const keyObjs = {
      apiURL,
      authentificationType,
      apiKey,
      baseIdValue,
      tableIdValue,
      viewIdValue,
      projectId,
    }

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/addAPIDataSource`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(keyObjs),
    }
    console.log('payload =', config)
    axios(config)
      .then(() => {
        triggerOnUpload()
        closeDataSourceModal()
      })
      .catch(console.error)
      .finally(setLoading(false))
  }

  return (
    <Form
      name="airtableDataForm"
      initialValues={{}}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        name="apiURL"
        extra={
          <Text small style={{ fontWeight: 300 }}>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  'The URL of your API. It must be a valid URL and start with https:// or http://.',
              }}
            />
          </Text>
        }
        rules={[
          {
            message: 'URL must be valid',
            validator: (_, value) =>
              isValidURL(value)
                ? Promise.resolve()
                : Promise.reject('URL is invalid'),
          },
        ]}
      >
        <Input width="100%" placeholder="API Url" required clearable vali />
      </Form.Item>

      <Form.Item
        name="authentificationType"
        extra={
          <Text small style={{ fontWeight: 300 }}>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  'If you API requires authentification, select the type of authentification for QuickAPI servers to work with your API.',
              }}
            />
          </Text>
        }
      >
        <Select placeholder="Authentification" onChange={setAuthentification}>
          {/* {authentificationOptions.map((option) => (
            <Select.Option value={option}>{option}</Select.Option>
          ))} */}
          <Select.Option value="none">None</Select.Option>
          <Select.Option value="header" disabled>
            Header
          </Select.Option>
        </Select>
      </Form.Item>
      {authentification === 'header' && (
        <Form.Item name="apiKey">
          <Input.Password
            name="_apiKey"
            defaultValue={''}
            width="100%"
            placeholder="API key"
            onChange={e => setAPIKey(e.target.value)}
            required
            clearable
          >
            <Text>
              This will create a header :{' '}
              <pre>
                X-Api-Key: Bearer{' '}
                {apiKey.length ? '*'.repeat(apiKey.length) : '[KEY]'}
              </pre>
            </Text>
          </Input.Password>
        </Form.Item>
      )}
      <Form.Item>
        <Button
          type="secondary"
          scale={2 / 3}
          htmlType="submit"
          loading={loading}
        >
          Validate API
        </Button>
      </Form.Item>
    </Form>
  )
}

export default APIForm
