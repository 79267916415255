import { InboxOutlined } from '@ant-design/icons'
import { Button, Loading, Select, Table, Text } from '@geist-ui/core'
import { ChevronDown } from '@geist-ui/icons'
import {
  Dropdown,
  Empty,
  Form,
  Menu,
  message,
  Modal,
  Space,
  Upload,
} from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FaFileCsv } from 'react-icons/fa'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { SiAirtable, SiGooglesheets } from 'react-icons/si'
import { TbApi } from 'react-icons/tb'
import { abbreviateNumber } from '../../config/functions'
import CreateIndex from './createIndex'
import AirtableDataForm from './integrations/airtable'
import APIForm from './integrations/api'
import SheetDataForm from './integrations/sheet'
import SyncAndUpdateData from './syncAndUpdateData'
import UpdateManual from './updateManual'

const { Dragger } = Upload

const DatasourceForm = ({
  id,
  projectInfo,
  openDataSourceModal,
  closeDataSourceModal,
}) => {
  const [submitting, setSubmitting] = useState(false)
  const [deployedList, setDeployedList] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDeploy, setLoadingDeploy] = useState(false)
  const [datasetModal, setDatasetModal] = useState(false)
  const [triggerOnUpload, setTriggerOnUpload] = useState(0)
  const [deployTrigger, setDeployTrigger] = useState('')
  const [datasourceType, setDatasourceType] = useState('')
  const [activeDeletion, setActiveDeletion] = useState(null)

  useEffect(() => {
    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/getDeployedList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ projectId: id }),
    }

    setLoading(true)
    axios(config)
      .then(function (response) {
        setDeployedList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }, [triggerOnUpload])

  const onFinish = values => {
    setSubmitting(true)
    const { csvDataFile } = values

    if (csvDataFile.file.response === undefined) {
      return message.error('CSV file is missing.')
    } else {
      values.csvDataFile = csvDataFile.file.response.fileRemoteLink
    }
  }

  const csvDataProps = {
    name: 'file',
    action: `${process.env.GATSBY_API_BASE_URL}/uploadFile`,
    multiple: false,
    maxCount: 1,
    data: {
      projectId: id,
    },

    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log('uploading')
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
        setTriggerOnUpload(triggerOnUpload + 1)
        closeDataSourceModal()
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  const renderAction = (value, rowData, rowIndex) => {
    const dataStringId = rowData.objShortKey
    const updateHandler = async () => {
      if (rowData.dataSize > 10000000) {
        message.success(
          'Deploying your data... We will send you a email when the process ends, if it take more than 5 minutes',
        )
      } else message.success('Deploying your data. This might take a while...')
      setDeployTrigger(dataStringId)
      setLoadingDeploy(true)
      var data = JSON.stringify({
        csvRemoteURL: rowData.dataURL,
        csvFileId: dataStringId,
        projectId: id,
        _id: rowData._id,
        objKey: rowData.objKey,
      })

      let pathEndpoint = 'deployDatabase'

      if (rowData.dataSourceType === 'airtable')
        pathEndpoint = 'deployAirtableData'

      if (rowData.dataSourceType === 'sheet') pathEndpoint = 'deploySheetData'

      var config = {
        method: 'post',
        url: `${process.env.GATSBY_API_BASE_URL}/${pathEndpoint}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          setTriggerOnUpload(triggerOnUpload + 1)
          setDeployTrigger('')
          setLoadingDeploy(false)
          message.success('Your data is deployed on the production.')
        })
        .catch(function (error) {
          setLoadingDeploy(false)
          setDeployTrigger('')
        })
    }
    return (
      <>
        {rowData.activeDeployedDataId === dataStringId ? (
          renderDeployDate(rowData?.deployedDate, rowData, rowIndex)
        ) : rowData.dataSourceType == 'api' ? (
          '-'
        ) : (
          <Button
            type="secondary"
            auto
            scale={1 / 3}
            font="12px"
            onClick={updateHandler}
            loading={loadingDeploy && deployTrigger === dataStringId}
          >
            Deploy
          </Button>
        )}
      </>
    )
  }

  const deleteThisDataset = () => {
    const { activeCollectionName, objShortKey, fileName } = activeDeletion
    var data = JSON.stringify({
      objShortKey,
      projectId: id,
      activeCollectionName,
    })

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/deleteDatabase`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        setTriggerOnUpload(triggerOnUpload + 1)
        setDeployTrigger('')
        setLoadingDeploy(false)
        message.success(`${fileName} is deleted.`)
        setDatasetModal(false)
      })
      .catch(function (error) {
        setLoadingDeploy(false)
        setDeployTrigger('')
        setDatasetModal(false)
      })
  }

  const renderActionList = (value, rowData, rowIndex) => {
    const { activeCollectionName, objShortKey, dataSourceType } = rowData
    return (
      <>
        <Dropdown
          style={{ cursor: 'pointer !important' }}
          trigger={['click']}
          overlay={
            <Menu
              items={
                dataSourceType == 'api'
                  ? [
                      {
                        key: '1',
                        label: (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            onClick={() => {
                              setActiveDeletion(rowData)
                              setDatasetModal(true)
                            }}
                          >
                            <RiDeleteBin6Line />

                            <span
                              style={{
                                fontSize: 13,
                                marginLeft: 5,
                              }}
                            >
                              Delete source
                            </span>
                          </div>
                        ),
                      },
                    ]
                  : [
                      {
                        key: '1',
                        label: <UpdateManual rowData={rowData} />,
                        disabled: dataSourceType === 'csv' ? true : false,
                      },
                      {
                        key: '2',
                        label: <SyncAndUpdateData rowData={rowData} />,
                        disabled: dataSourceType === 'csv' ? true : false,
                      },
                      {
                        key: '3',
                        label: <CreateIndex rowData={rowData} id={id} />,
                      },
                      {
                        key: '4',
                        label: (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            onClick={() => {
                              setActiveDeletion(rowData)
                              setDatasetModal(true)
                            }}
                          >
                            <RiDeleteBin6Line />

                            <span
                              style={{
                                fontSize: 13,
                                marginLeft: 5,
                              }}
                            >
                              Delete dataset
                            </span>
                          </div>
                        ),
                      },
                    ]
              }
            />
          }
        >
          <a onClick={e => e.preventDefault()}>
            <Space>
              <ChevronDown size={22} />
            </Space>
          </a>
        </Dropdown>
      </>
    )
  }

  const renderAddedDate = (value, rowData, rowIndex) => {
    return moment(value).fromNow()
  }

  const renderDeployDate = (value, rowData, rowIndex) => {
    return value ? moment(value).fromNow() : 'NA'
  }

  const renderRows = (value, rowData, rowIndex) => {
    return value ? abbreviateNumber(value) : '-'
  }
  const renderFileName = (value, rowData, rowIndex) => {
    return rowData.dataSourceType == 'api' ? (
      <a href={rowData?.dataURL} target="_blank">
        {value}
      </a>
    ) : (
      <a
        href={`https://mvp2.nyc3.digitaloceanspaces.com/${rowData.objKey}`}
        target="_blank"
      >
        {value}
      </a>
    )
  }

  const renderDataType = (value, rowData, rowIndex) => {
    if (value === 'csv')
      return (
        <div className="typeHtml">
          <FaFileCsv /> <span>CSV</span>
        </div>
      )
    else if (value === 'airtable')
      return (
        <div className="typeHtml">
          <SiAirtable /> <span>Airtable</span>
        </div>
      )
    else if (value === 'sheet')
      return (
        <div className="typeHtml">
          <SiGooglesheets /> <span>Google Sheet</span>
        </div>
      )
    else if (value === 'api')
      return (
        <div className="typeHtml">
          <TbApi /> <span>API</span>
        </div>
      )
    else return value
  }

  return (
    <div>
      {activeDeletion !== null && (
        <Modal
          open={datasetModal}
          onCancel={() => {
            setDatasetModal(false)
          }}
          title="Delete"
          footer={null}
        >
          <Text type="secondary">Delete this dataset.</Text>
          <Text>
            <p style={{ fontSize: 14 }}>
              Are you sure? you wants to delete{' '}
              <u style={{ fontWeight: 700 }}>{activeDeletion.fileName}</u>{' '}
              dataset. Then click on confirm button.
            </p>
          </Text>
          <Button type="secondary" onClick={() => deleteThisDataset()}>
            Confirm for deletion
          </Button>
        </Modal>
      )}
      {loading ? (
        <Loading />
      ) : deployedList.length > 0 ? (
        <>
          <Table data={deployedList}>
            <Table.Column
              prop="dataSourceType"
              label="Type"
              render={renderDataType}
            />
            <Table.Column
              prop="fileName"
              label="Name"
              render={renderFileName}
              width={150}
            />
            <Table.Column
              prop="totalRows"
              label="Total rows"
              render={renderRows}
            />
            <Table.Column
              prop="created_date"
              label="Added"
              render={renderAddedDate}
            />
            <Table.Column
              prop="Key"
              label="Last deployement"
              render={renderAction}
            />
            <Table.Column
              prop="Action"
              label="Actions"
              width={50}
              render={renderActionList}
            />
          </Table>
        </>
      ) : (
        <Empty
          description="You don't have any data source. Just click on add more data source."
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}

      <div className="datasource-form">
        <Modal
          open={openDataSourceModal}
          onCancel={() => {
            closeDataSourceModal()
          }}
          title="Data Upload"
          footer={null}
          width="700px"
        >
          <Text type="secondary">Add more data sources</Text>

          <Select
            placeholder="Select the Data Source"
            onChange={val => setDatasourceType(val)}
            width="100%"
          >
            <Select.Option value="csv" className="typeHtml">
              <FaFileCsv /> <span>CSV</span>
            </Select.Option>
            <Select.Option value="airtable" className="typeHtml">
              <SiAirtable /> <span>Airtable</span>
            </Select.Option>
            <Select.Option value="sheet" className="typeHtml">
              <SiGooglesheets /> <span>Google Sheet</span>
            </Select.Option>
            <Select.Option value="api" className="typeHtml">
              <TbApi /> <span>API</span>
            </Select.Option>
          </Select>
          <br />
          <br />
          {datasourceType === 'csv' && (
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item label="Upload CSV data" name="csvDataFile">
                <Dragger accept=".csv" {...csvDataProps} name="csvDataFile">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag your CSV data file.
                  </p>
                  <p className="ant-upload-hint">
                    Upload your data as CSV format. you will have the that CSV
                    data accessible with remote URL.
                  </p>
                </Dragger>
              </Form.Item>
            </Form>
          )}

          {datasourceType === 'airtable' && (
            <AirtableDataForm
              projectId={id}
              triggerOnUpload={() => setTriggerOnUpload(triggerOnUpload + 1)}
              closeDataSourceModal={closeDataSourceModal}
            />
          )}

          {datasourceType === 'sheet' && (
            <SheetDataForm
              projectId={id}
              uid={projectInfo.uid}
              triggerOnUpload={() => setTriggerOnUpload(triggerOnUpload + 1)}
              closeDataSourceModal={closeDataSourceModal}
            />
          )}

          {datasourceType === 'api' && (
            <APIForm
              projectId={id}
              triggerOnUpload={() => setTriggerOnUpload(triggerOnUpload + 1)}
              closeDataSourceModal={closeDataSourceModal}
            ></APIForm>
          )}
        </Modal>
      </div>
    </div>
  )
}

export default DatasourceForm
