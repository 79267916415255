import {
  Button,
  Card,
  Divider,
  Grid,
  Loading,
  Tabs,
  Text,
  Textarea,
} from '@geist-ui/core'
import { Alert, Form, message, Radio, Select, Input, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { MdContentCopy } from 'react-icons/md'
import { sortPricingArrayObj } from '../../config/functions'
import { HiSave } from 'react-icons/hi'
import { FiExternalLink } from 'react-icons/fi'
const { Option, OptGroup } = Select

const ProductPricing = ({ projectInfo, triggerRefresh }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState([])
  const [isStripeKeyValid, setIsStripeKeyValid] = useState(false)
  const [hasStripeKey, setHasStripeKey] = useState(
    projectInfo?.stripeKey?.length > 0,
  )
  const [hideStripeKeyForm, setHideStripeKeyForm] = useState(true)
  const [triggerThisComp, setTriggerThisComp] = useState(0)
  const [triggerList, setTriggerList] = useState(true) // Just do setTriggerList(!triggerList) to trigger useEffect (kinda bad practice)
  const [productList, setProductList] = useState([])
  const [pricingPlans, setPricingPlans] = useState(null)
  const [activePlanType, setActivePlanType] = useState(null)
  const [activePlanArray, setActivePlanArray] = useState([])
  const [activeProductList, setActiveProductList] = useState([])

  useEffect(() => {
    setHasStripeKey(projectInfo?.stripeKey?.length > 0)
    setSelectedProduct(projectInfo?.stripePricingTableObj?.selectedProducts)
  }, [projectInfo])

  useEffect(() => {
    if (hasStripeKey) {
      setHasStripeKey(true)
      setLoadingProducts(true)
      var config = {
        method: 'post',
        url: `${process.env.GATSBY_API_BASE_URL}/stripe/products`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          id: projectInfo.projectUniqueId,
        }),
      }

      axios(config)
        .then(function (response) {
          setProductList(response.data)
          setLoadingProducts(false)

          handleProductChange2(
            projectInfo.stripePricingTableObj.selectedProducts,
            response.data,
          )
        })
        .catch(function (error) {
          console.log(error)
          setLoadingProducts(false)
        })
    } else {
      // setHideStripeKeyForm(false);
    }
  }, [triggerList])

  const addStripeKey = values => {
    setLoading(true)
    const { projectUniqueId } = projectInfo
    var data = JSON.stringify({
      id: projectUniqueId,
      bodyData: values,
      flag: 'stripeKey',
    })

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/projects/${projectUniqueId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        const { error, products } = response.data
        if (error !== undefined) {
          message.info('Your Stripe Secret Key is not valid')
        } else {
          triggerRefresh()
          setTriggerThisComp(triggerThisComp + 1)
          setIsStripeKeyValid(true)
          message.success('Updated.')
          setHasStripeKey(true)
          setTriggerList(!triggerList)
          setHideStripeKeyForm(true)
        }
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error)
        message.info('There was an error updating your Stripe Secret Key')
        setLoading(false)
      })
  }

  const copythiHook = hookLink => {
    const textField = document.createElement('textarea')
    textField.innerText = hookLink
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    message.success('Webhook copied')
  }

  const onFinish = values => {
    let { publicPageObj, projectUniqueId } = projectInfo

    Object.keys(values).forEach(function (key) {
      // if (key === "selectedProducts") return;
      if (key === 'freeLimit') {
        publicPageObj[key] = parseInt(values[key])
      } else {
        publicPageObj[key] = values[key]
      }
    })

    var data = JSON.stringify({
      id: projectUniqueId,
      bodyData: publicPageObj,
      flag: 'publicpage',
    })

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/projects/${projectUniqueId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        const { error } = response.data

        if (error) {
          message.info(error)
        } else {
          // message.success("Your page is updated.");
          triggerRefresh()
        }
      })
      .catch(function (error) {
        message.info('There was an error updating your page')
        console.log(error)
      })

    var data = JSON.stringify({
      id: projectUniqueId,
      bodyData: {
        ...values,
        pricingPlans,
        selectedProducts: values['selectedProducts'],
      },
      flag: 'pricing',
    })
    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/projects/${projectUniqueId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        message.success('Updated.')
        triggerRefresh()
      })
      .catch(function (error) {
        message.info('There was an error updating your pricing')
        console.log(error)
      })
  }

  const handleProductChange = selectedPricing => {
    setSelectedProduct(selectedPricing)
    const formatedPricingPlanArray = {}
    const activeProductList = []

    for (let i = 0; i < selectedPricing.length; i++) {
      const [productId, pricingId] = selectedPricing[i].split('-')
      const productObjIndex = productList.findIndex(x => x.id === productId)
      const allPricingOfThisProduct = productList[productObjIndex].pricingList
      const pricingObjIndex = allPricingOfThisProduct.findIndex(
        x => x.id === pricingId,
      )
      if (activeProductList.findIndex(x => x.id === productId) === -1)
        activeProductList.push(productList[productObjIndex])

      let { interval, interval_count } =
        allPricingOfThisProduct[pricingObjIndex].recurring

      if (interval === 'month' && interval_count === 1) interval = 'Monthly'
      if (interval === 'month' && interval_count > 1)
        interval = `every ${interval_count} months`
      if (interval === 'year' && interval_count === 1) interval = 'Yearly'
      if (interval === 'year' && interval_count > 1)
        interval = `Every ${interval_count} years`

      if (formatedPricingPlanArray[interval] === undefined)
        formatedPricingPlanArray[interval] = []

      formatedPricingPlanArray[interval].push({
        product: productList[productObjIndex],
        pricing: allPricingOfThisProduct[pricingObjIndex],
      })
    }

    setActiveProductList(activeProductList)

    setActivePlanType(Object.keys(formatedPricingPlanArray)[0])
    setActivePlanArray(Object.keys(formatedPricingPlanArray))

    setPricingPlans(formatedPricingPlanArray)
    onFinish({ ...form.getFieldsValue(), selectedProducts: selectedPricing })
  }

  const handleProductChange2 = (selectedPricing, productsList2) => {
    const formatedPricingPlanArray = {}
    const activeProductList = []

    for (let i = 0; i < selectedPricing.length; i++) {
      const [productId, pricingId] = selectedPricing[i].split('-')
      const productObjIndex = productsList2.findIndex(x => x.id === productId)
      const allPricingOfThisProduct = productsList2[productObjIndex].pricingList
      const pricingObjIndex = allPricingOfThisProduct.findIndex(
        x => x.id === pricingId,
      )
      if (activeProductList.findIndex(x => x.id === productId) === -1)
        activeProductList.push(productsList2[productObjIndex])

      let { interval, interval_count } =
        allPricingOfThisProduct[pricingObjIndex].recurring

      if (interval === 'month' && interval_count === 1) interval = 'Monthly'
      if (interval === 'month' && interval_count > 1)
        interval = `every ${interval_count} months`
      if (interval === 'year' && interval_count === 1) interval = 'Yearly'
      if (interval === 'year' && interval_count > 1)
        interval = `Every ${interval_count} years`

      if (formatedPricingPlanArray[interval] === undefined)
        formatedPricingPlanArray[interval] = []

      formatedPricingPlanArray[interval].push({
        product: productsList2[productObjIndex],
        pricing: allPricingOfThisProduct[pricingObjIndex],
      })
    }

    setActiveProductList(activeProductList)

    setActivePlanType(Object.keys(formatedPricingPlanArray)[0])
    setActivePlanArray(Object.keys(formatedPricingPlanArray))

    setPricingPlans(formatedPricingPlanArray)
  }

  return (
    <div className="plans-customize">
      <br />
      <div>
        {
          <div
            style={{
              margin: 4,
              padding: 30,
              border: 'solid 1px #DDDDDD',
              borderRadius: 10,
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Text h2>Stripe</Text>

            {/* {activePlanArray.length > 0 && (
              <Text
                type="secondary"
                style={{
                  backgroundColor: '#FFB0BA',
                  border: '1px solid rgb(242, 117, 133)',
                  padding: '5px 15px 5px 15px',
                  color: 'black',
                }}
              >
                Please remove your pricing plans before changing your Stripe
                Secret Key
              </Text>
            )} */}
            <Form
              name="stripeKey"
              onFinish={addStripeKey}
              autoComplete="off"
              layout="vertical"
            >
              <p style={{ fontWeight: 700 }}>Create a Stripe Secret API Key</p>
              <Form.Item
                label="Stripe Secret API Key"
                initialValue={projectInfo?.stripeKey ?? ''}
                name="stripeKey"
                extra={
                  // <Text small>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        'You can create a secret API Key on your Stripe <a href="https://dashboard.stripe.com/developers" target="_blank">Developers Dashboard</a>, then on the <a href="https://dashboard.stripe.com/apikeys">API Keys tab</a>. You can also use previously created secret keys.',
                    }}
                  />
                  // </Text>
                }
              >
                <Input.Password
                  placeholder="Stripe Private Key"
                  width="100%"
                  className={hasStripeKey ? 'readonly-input' : ''}
                  required
                  readOnly={hasStripeKey}
                  autoComplete="off"
                  type="secondary"
                  style={{ padding: 8 }}
                />
              </Form.Item>
              <br />
              <div style={{ fontWeight: 700 }}>
                Create a Stripe Webhook with the event{' '}
                <pre
                  style={{
                    display: 'inline',
                    background: 'rgba(0,0,0,0.1)',
                    padding: '0 5px 0 5px',
                  }}
                >
                  checkout.session.completed
                </pre>
                , and with the following endpoint :
              </div>
              <div className="webhook-link">
                <pre style={{ margin: 0 }}>
                  {projectInfo.projectHostName}
                  /stripe/webhook
                </pre>
                <MdContentCopy
                  onClick={() =>
                    copythiHook(`${projectInfo.projectHostName}/stripe/webhook`)
                  }
                />
              </div>
              <Text small>
                <span
                  style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                  dangerouslySetInnerHTML={{
                    __html: `You can create a Webhook on the Stripe <a href="https://dashboard.stripe.com/developers" target="_blank">
                          Developper Dashboard
                        </a>
                        , then on the <a href="https://dashboard.stripe.com/webhooks">Webhooks tab</a>.`,
                  }}
                />
              </Text>
              <br />
              <br />
              <Form.Item
                label="Stripe Webhook Signing Secret"
                name="stripeHookKey"
                required
                initialValue={projectInfo.stripeHookKey}
                extra={
                  <Text small>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          'The Signing Secret of your previously created webhook.',
                      }}
                    />
                  </Text>
                }
              >
                <Input.Password
                  placeholder="Stripe Webhook Signing Secret"
                  width="100%"
                  required
                  autoComplete="off"
                  type="secondary"
                  style={{ padding: 8 }}
                />
              </Form.Item>
              <Form.Item>
                <Button type="secondary" htmlType="submit" loading={loading}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        }

        {loadingProducts && <Loading />}

        {
          <>
            {/* <Button
              type="secondary"
              htmlType="submit"
              scale={2 / 3}
              onClick={() => setHideStripeKeyForm(false)}
            >
              {hasStripeKey ? 'Change Stripe Details' : 'Link Stripe Account'}
            </Button>
            <br />
            <br /> */}
            <br />
            <br />
            <Text h2>Pricing plans for {projectInfo.publicPageObj.name}</Text>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              initialValues={projectInfo.stripePricingTableObj}
              onFinish={onFinish}
              autoComplete="off"
              // min width of 400px
            >
              <Text
                type="secondary"
                style={{
                  backgroundColor: '#fffbe6',
                  border: '1px solid #ffe58f',
                  padding: '5px 15px 5px 15px',
                  color: 'black',
                }}
              >
                {`Checkout our guide on `}
                <a href="https://quickapi.io/blog/add-pricing" target="_blank">
                  how to add pricing plans with Stripe
                </a>
              </Text>
              <Form.Item
                label="Products"
                name="selectedProducts"
                extra={
                  <Text small>
                    <span>
                      {!hasStripeKey
                        ? 'Please link your Stripe account first.'
                        : productList.length == 0 &&
                          'You mush create products on your Stripe Dashboard first.'}
                    </span>
                  </Text>
                }
              >
                <Select
                  disabled={!hasStripeKey}
                  mode="multiple"
                  style={{ width: 600 }}
                  onChange={e => {
                    handleProductChange(e)
                  }}
                  value={selectedProduct}
                  placeholder="Select the product pricing"
                >
                  {productList.map((product, key) => {
                    const { name, pricingList, id } = product
                    return (
                      <OptGroup label={name} key={key}>
                        {pricingList.map((pricing, key2) => {
                          const { interval_count, interval } = pricing.recurring

                          let intervalString = interval
                          if (interval_count > 1)
                            intervalString = `every ${interval_count} ${interval}`

                          return (
                            <Option
                              value={`${id}-${pricing.id}`}
                              key={pricing.id}
                            >
                              {name} — ${pricing.unit_amount / 100} /{' '}
                              {intervalString}
                            </Option>
                          )
                        })}
                      </OptGroup>
                    )
                  })}
                </Select>
              </Form.Item>
            </Form>

            <div className="pricing-cards">
              <Card style={styles.card}>
                <Form
                  form={form}
                  layout="vertical"
                  name="basic"
                  initialValues={projectInfo.stripePricingTableObj}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    <Text h4 style={{ fontSize: 22, marginBottom: 20 }}>
                      Free
                    </Text>
                  </div>
                  {/* <Text h4 style={{ fontSize: 22, marginBottom: 'auto' }}>
                    Free
                  </Text> */}
                  <div style={{ margin: '15px 0' }}>
                    <Form.Item
                      label={`API limit for free users`}
                      name="freeLimit"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      extra={
                        <Text small>
                          <span>
                            Set the limit to 0 to remove the free plan
                          </span>
                        </Text>
                      }
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        value={projectInfo.publicPageObj.freeLimit}
                      />
                    </Form.Item>
                  </div>

                  <div style={{ display: 'flex', paddingTop: 5 }}>
                    <Form.Item>
                      <Button
                        type="secondary"
                        htmlType="submit"
                        width={4 / 5}
                        scale={2 / 3}
                      >
                        Save
                      </Button>
                    </Form.Item>
                    {/* <a
                            href={projectInfo.projectHostName}
                            target="_blank"
                            className="btn-link"
                            style={{ marginLeft: '10px' }}
                          >
                            Preview
                            <FiExternalLink />
                          </a> */}
                  </div>
                </Form>
              </Card>
              {/* <Grid.Container gap={1.5}> */}
              {pricingPlans !== null &&
                pricingPlans.length != 0 &&
                pricingPlans[activePlanType] &&
                sortPricingArrayObj(
                  pricingPlans[activePlanType],
                  'unit_amount',
                ).map(pL => {
                  if (
                    !selectedProduct.includes(
                      pL.product.id + '-' + pL.pricing.id,
                    )
                  )
                    return
                  return (
                    <Card key={pL.pricing.id} style={styles.card}>
                      <Form
                        form={form}
                        layout="vertical"
                        name="basic"
                        initialValues={projectInfo.stripePricingTableObj}
                        onFinish={onFinish}
                        autoComplete="off"
                      >
                        <Text
                          small
                          style={{ display: 'block', marginBottom: '3px' }}
                        >
                          {pL.product.name}
                        </Text>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                          }}
                        >
                          <Text h4 style={{ fontSize: 22 }}>
                            <span className="currency">
                              {pL.pricing.currency == 'usd'
                                ? '$'
                                : pL.pricing.currency == 'eur'
                                ? '€'
                                : pL.pricing.currency}
                            </span>
                            <span className="amount">
                              {pL.pricing.unit_amount / 100}
                            </span>
                            <span className="recurrence">
                              {`${
                                pL.pricing.type == 'recurring'
                                  ? `/ ${
                                      pL.pricing.recurring.interval_count > 1
                                        ? pL.pricing.recurring.interval_count
                                        : ''
                                    } ${pL.pricing.recurring.interval}${
                                      pL.pricing.recurring.interval_count > 1
                                        ? 's'
                                        : ''
                                    }`
                                  : ''
                              }`}
                            </span>
                          </Text>
                        </div>

                        <div style={{ margin: '15px 0' }}>
                          <Form.Item
                            label={`API limit for this plan`}
                            name={pL.pricing.id}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                            extra={
                              <Text small>
                                <span>&nbsp;</span>
                              </Text>
                            }
                          >
                            <InputNumber style={{ width: '100%' }} />
                          </Form.Item>
                        </div>

                        <div style={{ display: 'flex', paddingTop: 5 }}>
                          <Form.Item>
                            <Button
                              type="secondary"
                              htmlType="submit"
                              width={4 / 5}
                              scale={2 / 3}
                            >
                              Save
                            </Button>
                          </Form.Item>
                          {/* <a
                            href={projectInfo.projectHostName}
                            target="_blank"
                            className="btn-link"
                            style={{ marginLeft: '10px' }}
                          >
                            Preview
                            <FiExternalLink />
                          </a> */}
                        </div>
                      </Form>
                    </Card>
                  )
                })}
              {/* </Grid.Container> */}
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default ProductPricing

const styles = {
  card: {
    borderRadius: '20px',
  },
}
