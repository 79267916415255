import { VscRefresh } from 'react-icons/vsc'
import React, { useEffect, useState } from 'react'
import { Button, Table, Text } from '@geist-ui/core'
import { message, Modal } from 'antd'
import moment from 'moment'
import { RefreshCcw } from '@geist-ui/icons'
import axios from 'axios'

const UpdateManual = ({ rowData }) => {
  const {
    dataSourceType,
    totalRows,
    updated_date,
    dataURL,
    objShortKey,
    projectId,
    _id,
    fileName,
    activeCollectionName,
  } = rowData
  const [state, setState] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [depLogList, setDepLogList] = useState(null)
  const [triggerChange, setTriggerChange] = useState(0)

  useEffect(() => {
    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/deployLogs`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        _id,
        projectId,
      }),
    }

    axios(config)
      .then(function (response) {
        setDepLogList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [triggerChange])

  const handler = () => {
    const { activeCollectionName } = rowData
    if (activeCollectionName !== null) {
      setState(true)
    } else {
      message.error('To update, first deploy this data source.')
    }
  }
  const closeHandler = event => {
    setState(false)
    console.log('closed')
  }
  const updateThisDataSource = () => {
    setUpdating(true)

    message.success('We are updating...')
    var data = JSON.stringify({
      csvRemoteURL: dataURL,
      csvFileId: objShortKey,
      projectId,
      _id: _id,
      activeCollectionName,
    })

    let pathEndpoint = ''

    if (dataSourceType === 'airtable') pathEndpoint = 'updateAirtableData'

    if (dataSourceType === 'sheet') pathEndpoint = 'updateSheetData'

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/${pathEndpoint}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        setUpdating(false)
        setTriggerChange(triggerChange + 1)
        message.success('Your data is updated successfully.')
      })
      .catch(function (error) {
        setUpdating(false)
      })
  }
  const renderAction = (value, rowData, rowIndex) => {
    const updateHandler = () => {
      var config = {
        method: 'post',
        url: `${process.env.GATSBY_API_BASE_URL}/swapDeploy`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          projectId,
          _id: value,
          depId: rowData.depId,
        },
      }

      axios(config)
        .then(function (response) {
          setTriggerChange(triggerChange + 1)
          message.success('Your new data source is deployed.')
        })
        .catch(function (error) {
          message.info('There are some issues.')
        })
    }
    return (
      <Button
        type="secondary"
        auto
        scale={1 / 3}
        font="12px"
        onClick={updateHandler}
      >
        Deploy
      </Button>
    )
  }
  const renderName = (value, rowData, rowIndex) => {
    return (
      <span>
        {value}{' '}
        {rowData.active && (
          <>
            →
            <span
              style={{
                color: '#094861',
                fontWeight: 700,
                backgroundColor: '#e2fef1',
                padding: 3,
              }}
            >
              Published
            </span>
          </>
        )}
      </span>
    )
  }
  return (
    <>
      <Modal
        title="Update"
        open={state}
        onOk={() => handler()}
        onCancel={closeHandler}
        width="600px"
        footer={null}
      >
        <h4>
          Manually update this data source(
          {rowData.fileName})
        </h4>
        <Text type="secondary" small>
          Name: {fileName} — last updated: {moment(updated_date).fromNow()} —
          Total rows now: {totalRows}
        </Text>
        <br />
        <br />
        <Button
          icon={<RefreshCcw />}
          onClick={() => updateThisDataSource()}
          loading={updating}
          scale={2 / 3}
          type="secondary"
          disabled={updating}
        >
          Update now
        </Button>
        <br />
        {updating && (
          <Text type="secondary" small>
            Don't close the window, we are updating this data source.
          </Text>
        )}
        <br />
        <br />
        {depLogList !== null && (
          <Table data={depLogList}>
            <Table.Column prop="name" label="Deploy Name" render={renderName} />
            <Table.Column
              prop="updated_date"
              label="updated_date"
              render={val => moment(val).fromNow()}
            />
            <Table.Column prop="totalRows" label="total rows" />
            <Table.Column prop="_id" label="Action" render={renderAction} />
          </Table>
        )}
      </Modal>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => handler()}
      >
        <VscRefresh />

        <span
          style={{
            fontSize: 13,
            marginLeft: 5,
          }}
        >
          Update manually
        </span>
      </div>
    </>
  )
}

export default UpdateManual
