import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router'

import SEO from '../blocks/layout/seo'
import AppContainer from '../blocks/layout/appContainer'
import ProjectList from '../blocks/dashboard/projectList'

import { Firebase } from '../config/firebase'
import ProjectDetails from '../blocks/dashboard/projectDetails'

const Projects = ({ location }) => {
  const [uId, setUId] = useState('')

  function onChange(user) {
    setUId(user.uid)
  }

  useEffect(() => {
    const unsubscribe = Firebase.auth().onAuthStateChanged(onChange)

    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  return (
    <>
      <SEO title="Dashboard — QuickAPI" />
      <AppContainer menuKey="1">
        <Router>
          <ProjectList path="/projects" uid={uId} location={location} />
          <ProjectDetails path="/projects/:id" uid={uId} location={location} />
        </Router>
      </AppContainer>
    </>
  )
}

export default Projects
