import React, { useEffect, useState } from 'react'
import { Divider, Steps } from 'antd'

import axios from 'axios'
import { Description, Button, Tabs, Text, Loading } from '@geist-ui/core'
import { BsInfoSquare } from 'react-icons/bs'
import { HiOutlineUsers } from 'react-icons/hi'
import DatasourceForm from './datasourceForm'
import EndpointConfig from './endpointConfig'
import UsersTable from './usersTable'
import { MdOutlinePublic } from 'react-icons/md'
import { RiSettingsLine } from 'react-icons/ri'
import { GrIntegration, GrStripe } from 'react-icons/gr'
import PageConstomize from './pageCustomize'
import AnalyticsSetting from './analyticsConfig'
import ProjectSettings from './settings'
import {
  Copy,
  ExternalLink,
  FolderPlus,
  SkipBack,
  SkipForward,
} from '@geist-ui/icons'
import ProductPricing from './productPricing'
import ActiveProjectUsage from '../layout/activeProjectUsage'

const { Step } = Steps

const ProjectDetails = ({ id }) => {
  const [loading, setLoading] = useState(false)
  const [stepNumber, setStepNumber] = useState(0)
  const [projectInfo, setProjectInfo] = useState(null)
  const [projectTab, setProjectTab] = useState('1')
  const [hitRefresh, setHitRefresh] = useState(0)
  const [dataSourceModal, setDataSourceModal] = useState(false)

  function getProjectDetails() {
    setLoading(true)
    axios
      .get(`${process.env.GATSBY_API_BASE_URL}/projects/${id}`)
      .then(result => setProjectInfo(result.data))
      .catch(error => console.log(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getProjectDetails()
  }, [])

  useEffect(() => {
    getProjectDetails()
  }, [hitRefresh])

  const changeNextStep = () => {
    if (stepNumber < 2) {
      setStepNumber(stepNumber + 1)
    }
  }

  const changePreviuosStep = () => {
    if (stepNumber > 0) {
      setStepNumber(stepNumber - 1)
    }
  }

  const projectTabHandle = x => {
    setProjectTab(x)
  }

  const copyId = projectId => {
    const textField = document.createElement('textarea')
    textField.innerText = projectId
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  return (
    <>
      <div className="details-header">
        <div>
          {projectInfo && (
            <Text type="secondary" style={{ display: 'inline' }}>
              Public Page →{' '}
              <a
                href={`${projectInfo.projectHostName}?adminKey=${projectInfo.adminKey}`}
                style={{
                  color: '#000',
                  textDecoration: 'underline',
                }}
                target="_blank"
              >
                {projectInfo.projectHostName} <ExternalLink size={11} />
              </a>
            </Text>
          )}
          &nbsp; &nbsp;|&nbsp;&nbsp;
          <ActiveProjectUsage activeProjectId={id} uid={projectInfo?.uid} />
        </div>
        <div>
          <a
            href="https://cal.com/louislafont/quickapi-meetup"
            target="_blank"
            style={{
              textDecoration: 'underline',
              color: '#000',
            }}
          >
            <Text small type="secondary">
              Need help to start?
            </Text>
          </a>
        </div>
      </div>
      <br />
      <Tabs initialValue={projectTab} leftSpace={0} onChange={projectTabHandle}>
        <Tabs.Item
          label={
            <>
              <BsInfoSquare /> Project Setup
            </>
          }
          value="1"
        >
          <br />
          {projectInfo !== null && (
            <div className="steps-container">
              <Steps
                size="small"
                direction="vertical"
                current={stepNumber}
                onChange={current => setStepNumber(current)}
              >
                <Step
                  title="Data Source"
                  description="Connect with your data source like CSV, Airtable, Google Sheet."
                />
                <Step
                  title="Endpoints"
                  description="Setup API endpoints for your product"
                />
              </Steps>
              <div className="steps-content">
                {stepNumber === 0 && projectInfo !== null && (
                  <>
                    <div className="data-source-head">
                      <Button
                        type="secondary"
                        scale={2 / 3}
                        icon={<FolderPlus />}
                        width="180px"
                        onClick={() => setDataSourceModal(true)}
                      >
                        Add more data source
                      </Button>
                    </div>

                    <DatasourceForm
                      id={id}
                      projectInfo={projectInfo}
                      openDataSourceModal={dataSourceModal}
                      closeDataSourceModal={() => setDataSourceModal(false)}
                    />
                  </>
                )}
                {stepNumber === 1 && projectInfo !== null && (
                  <EndpointConfig id={id} projectInfo={projectInfo} />
                )}
                <br />
                <br />
                <Button
                  onClick={changePreviuosStep}
                  scale={2 / 3}
                  icon={<SkipBack />}
                >
                  Previous step
                </Button>
                &nbsp; &nbsp;
                {stepNumber === 0 && (
                  <Button
                    type="secondary"
                    onClick={changeNextStep}
                    scale={2 / 3}
                    icon={<SkipForward />}
                    width="180px"
                    iconRight={true}
                  >
                    Next: Setup endpoints
                  </Button>
                )}
              </div>
            </div>
          )}
        </Tabs.Item>
        <Tabs.Item
          label={
            <>
              <MdOutlinePublic /> Page Customize{' '}
            </>
          }
          value="3"
        >
          {projectTab === '3' && projectInfo !== null && (
            <PageConstomize
              projectInfo={projectInfo}
              triggerRefresh={() => setHitRefresh(hitRefresh + 1)}
            />
          )}
        </Tabs.Item>
        <Tabs.Item
          label={
            <>
              <GrStripe /> Pricing{' '}
            </>
          }
          value="4"
        >
          {projectTab === '4' && projectInfo !== null && (
            <ProductPricing
              projectInfo={projectInfo}
              triggerRefresh={() => setHitRefresh(hitRefresh + 1)}
            />
          )}
        </Tabs.Item>
        <Tabs.Item
          label={
            <>
              <HiOutlineUsers /> Users{' '}
            </>
          }
          value="2"
        >
          {projectTab === '2' && (
            <UsersTable
              uid={projectInfo.uid}
              projectId={projectInfo.projectUniqueId}
              publicPageObj={projectInfo.publicPageObj}
            />
          )}
        </Tabs.Item>
        {/* <Tabs.Item
                              label={
                                    <>
                                          <ImStatsBars2 /> Analytics{' '}
                                    </>
                              }
                              value="5"
                        >
                              {projectInfo !== null && (
                                    <AnalyticsSetting
                                          projectInfo={projectInfo}
                                          triggerRefresh={() =>
                                                setHitRefresh(hitRefresh + 1)
                                          }
                                    />
                              )}
                        </Tabs.Item> */}
        <Tabs.Item
          label={
            <>
              <GrIntegration /> Integrations{' '}
            </>
          }
          value="6"
        >
          {projectTab === '6' && projectInfo !== null && (
            <AnalyticsSetting
              projectInfo={projectInfo}
              triggerRefresh={() => setHitRefresh(hitRefresh + 1)}
            />
          )}
        </Tabs.Item>
        <Tabs.Item
          label={
            <>
              <RiSettingsLine /> Project Settings{' '}
            </>
          }
          value="7"
        >
          {projectTab === '7' && projectInfo !== null && (
            <ProjectSettings projectInfo={projectInfo} />
          )}
        </Tabs.Item>
      </Tabs>
    </>
  )
}

export default ProjectDetails
