import React, { useState, useEffect } from 'react'
import { Drawer, Form, Input, Select, message } from 'antd'
import axios from 'axios'
import { Button, Text } from '@geist-ui/core'
import { RiAddFill } from 'react-icons/ri'
import { FolderPlus } from '@geist-ui/icons'
import RenderDataType from '../../components/RenderDataType'
import stringToFileName from '../../utils/stringToFileName'

const { Option } = Select

const SetupEndpoint = ({ id, projectInfo, triggerPage }) => {
  const [selectedType, setSelectedType] = useState(null)
  const [path, setPath] = useState('')
  const [userSetPath, setUserSetPath] = useState(false)
  const [endpoint, setEndpoint] = useState(null)
  const [endpointList, setEndpointList] = useState([])
  const [dataSourceList, setDataSourceList] = useState([])

  const [visible, setVisible] = useState(false)
  useEffect(() => {
    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/getProjectEndpoints`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        projectId: id,
      }),
    }

    axios(config)
      .then(function (response) {
        setEndpointList(response.data.endpoints)
        setDataSourceList(response.data.csvData)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const addNewEndpoint = values => {
    const isPathAvailable = endpointList.findIndex(x => x.path === values.path)

    if (isPathAvailable === -1) {
      const reservedPaths = [
        'login',
        'about',
        'app',
        'signup',
        'contact',
        'about',
        'pricing',
        'auth',
        'docs',
        'stripe',
      ]
      const isPathValid = reservedPaths.findIndex(x => x === values.path)
      if (isPathValid < 0 && values.path.match('^(?!-)[a-z0-9-]+(?<!-)$')) {
        values.added_date = Date.now()
        values.updated_date = Date.now()
        if (!values.aboutPath) values.aboutPath = ''
        values.projectId = id
        const dataSourceObjIndex = dataSourceList.findIndex(
          x => x._id === values.dataSource,
        )
        values.dataSourceObjId = dataSourceList[dataSourceObjIndex]._id

        var config = {
          method: 'post',
          url: `${process.env.GATSBY_API_BASE_URL}/addProjectEndpoints`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(values),
        }

        axios(config)
          .then(function (response) {
            setVisible(false)
            message.success('Endpoint added.')
            triggerPage()
          })
          .catch(function (error) {
            console.log(error)
          })
      } else {
        message.info(
          `This path name ${values.path} is not allowed, choose different path endpoint.`,
        )
      }
    } else {
      message.info(
        'This path name is already assign, choose different path endpoint.',
      )
    }
  }

  return (
    <div>
      <Button
        type="secondary"
        icon={<FolderPlus />}
        scale={2 / 3}
        onClick={showDrawer}
        width="180px"
      >
        Create new endpoint
      </Button>
      <Drawer
        title="Setup new endpoint"
        placement="left"
        onClose={onClose}
        open={visible}
        width="600px"
      >
        <Form
          name="basic"
          onFinish={addNewEndpoint}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            name="dataSource"
            label="Select Data Source"
            help="Don't see your data source? You need to deploy it in the previous step."
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select the data for which you are setting endpoints"
              allowClear
              onChange={value => {
                const type = dataSourceList.find(
                  e => e._id == value,
                )?.dataSourceType
                setSelectedType(type)
                if (!userSetPath) {
                  if (type == 'api') {
                    setPath('')
                  } else
                    setPath(
                      stringToFileName(
                        dataSourceList.find(e => e._id == value)?.fileName,
                      ),
                    )
                }
              }}
            >
              {dataSourceList.map((ds, key) => {
                if (
                  ds.activeCollectionName !== null ||
                  ds.dataSourceType == 'api'
                )
                  return (
                    <option value={ds._id} key={key}>
                      {RenderDataType(ds.dataSourceType, ds.fileName)}
                    </option>
                  )
              })}
            </Select>
          </Form.Item>
          {selectedType == 'api' && (
            <Text
              type="secondary"
              style={{
                backgroundColor: '#fffbe6',
                border: '1px solid #ffe58f',
                padding: '5px 15px 5px 15px',
                color: 'black',
              }}
            >
              We will forward all requests from this endpoint to your API
            </Text>
          )}
          <Form.Item
            label="Endpoint"
            name="path"
            extra={
              <span>
                Endpoint path will be {`${projectInfo.projectHostName}/`}
                <b>{endpoint}</b>
              </span>
            }
            help="Choose a name for your endpoint. If that's your first endpoint, you can use 'all'."
            rules={[
              {
                required: true,
                message: 'You need to choose a name for your endpoint.',
              },
            ]}
          >
            <Input
              addonBefore={`${projectInfo.projectHostName}/`}
              placeholder="all"
              value={endpoint}
              onChange={e => setEndpoint(e.target.value)}
            />
          </Form.Item>
          {selectedType == 'api' && (
            <Form.Item
              label="Documentation"
              name="documentation"
              help="Add a link to the documentation for this endpoint."
            >
              <Input placeholder="https://example.com/docs" />
            </Form.Item>
          )}
          <Form.Item label="About this endpoint" name="aboutPath">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button
              type="secondary"
              htmlType="submit"
              scale={0.75}
              icon={<RiAddFill />}
              width="180px"
            >
              Add the endpoint
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  )
}

export default SetupEndpoint
