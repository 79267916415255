import React, { useEffect, useState } from 'react'
import { Drawer, Form, Input, Select, message } from 'antd'
import axios from 'axios'
import { Button, Text } from '@geist-ui/core'
import { BsSave } from 'react-icons/bs'
import RenderDataType from '../../components/RenderDataType'
import isValidURL from '../../utils/isValidURL'

const { Option } = Select

const EditEndpoint = ({
  id,
  projectInfo,
  triggerPage,
  enObj,
  showEditDrawer,
  handleEditDrawer,
  endpointList,
  dataSourceList,
}) => {
  const [form] = Form.useForm()
  const [selectedType, setSelectedType] = useState(
    dataSourceList.find(e => e._id == enObj?.dataSource)?.dataSourceType,
  )

  useEffect(() => {
    setSelectedType(
      dataSourceList.find(e => e._id == enObj?.dataSource)?.dataSourceType,
    )
  }, [enObj])

  //   const [visible, setVisible] = useState(showEditDrawer);
  useEffect(() => {
    form.setFieldsValue(enObj)
  }, [enObj, form])

  const editEndpoint = values => {
    const reservedPaths = [
      'login',
      'about',
      'app',
      'signup',
      'contact',
      'about',
      'pricing',
      'auth',
      'docs',
      'stripe',
    ]
    const isPathValid = reservedPaths.findIndex(x => x === values.path)
    if (isPathValid < 0 && values.path.match('^(?!-)[a-z0-9-]+(?<!-)$')) {
      values.added_date = Date.now()
      values._id = enObj._id
      values.updated_date = Date.now()
      values.projectId = id
      if (!values.aboutPath) values.aboutPath = ''
      const dataSourceObjIndex = dataSourceList.findIndex(
        x => x._id === values.dataSource,
      )
      values.dataSourceObjId = dataSourceList[dataSourceObjIndex]._id
      var config = {
        method: 'post',
        url: `${process.env.GATSBY_API_BASE_URL}/editProjectEndpoints`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(values),
      }

      axios(config)
        .then(function (response) {
          handleEditDrawer()
          message.success('Endpoint updated.')
          triggerPage()
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      message.info(
        `This path name ${values.path} is not allowed, choose different path endpoint.`,
      )
    }
  }

  return (
    <div>
      <Drawer
        title="Setup new endpoint"
        placement="left"
        onClose={handleEditDrawer}
        open={showEditDrawer}
        width="600px"
      >
        <Form
          form={form}
          name="basic"
          onFinish={editEndpoint}
          autoComplete="off"
          layout="vertical"
          initialValues={enObj}
        >
          <Form.Item
            name="dataSource"
            label="Select Data Source"
            help="Don't see your data source? You need to deploy it in the previous step."
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select the data for which you are setting endpoints"
              allowClear
              onChange={value =>
                setSelectedType(
                  dataSourceList.find(e => e._id == value)?.dataSourceType,
                )
              }
            >
              {dataSourceList.map((ds, key) => {
                if (
                  ds.activeCollectionName !== null ||
                  ds.dataSourceType == 'api'
                )
                  return (
                    <option value={ds._id} key={key}>
                      {RenderDataType(ds.dataSourceType, ds.fileName)}
                    </option>
                  )
              })}
            </Select>
          </Form.Item>
          {selectedType == 'api' && (
            <Text
              type="secondary"
              style={{
                backgroundColor: '#fffbe6',
                border: '1px solid #ffe58f',
                padding: '5px 15px 5px 15px',
                color: 'black',
              }}
            >
              We will forward all requests from this endpoint to your API
            </Text>
          )}
          <Form.Item
            label="Endpoint"
            name="path"
            help="Choose a name for your endpoint. If that's your first endpoint, you can use 'all'."
            rules={[
              {
                required: true,
                message: 'You need to choose a name for your endpoint.',
              },
            ]}
          >
            <Input
              addonBefore={`${projectInfo.projectHostName}/`}
              placeholder="all"
            />
          </Form.Item>
          {selectedType == 'api' && (
            <Form.Item
              label="Documentation"
              name="documentation"
              help="Add a link to the documentation for your endpoint."
              rules={[
                {
                  message: 'URL must be valid',
                  validator: (_, value) =>
                    value
                      ? isValidURL(value)
                        ? Promise.resolve()
                        : Promise.reject('URL is invalid')
                      : Promise.resolve(),
                },
              ]}
            >
              <Input placeholder="https://example.com/docs" />
            </Form.Item>
          )}
          <Form.Item label="About this endpoint" name="aboutPath">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button
              type="secondary"
              htmlType="submit"
              scale={0.75}
              icon={<BsSave />}
              width="200px"
            >
              Update this endpoint
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  )
}

export default EditEndpoint
