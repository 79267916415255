import { MdSchedule } from 'react-icons/md'
import React, { useState } from 'react'
import { Button, Select, Text } from '@geist-ui/core'
import { message, Modal } from 'antd'

const SyncAndUpdateData = ({ rowData }) => {
  const { dataSourceType } = rowData
  const [state, setState] = useState(false)
  const handler = () => {
    console.log(rowData.activeCollectionName)
    const { activeCollectionName } = rowData
    if (activeCollectionName !== null) {
      setState(true)
    } else {
      message.error('To setup a schedule, first deploy this data source.')
    }
  }
  const closeHandler = event => {
    setState(false)
    console.log('closed')
  }
  return (
    <>
      <Modal
        title="Schedule"
        open={state}
        onOk={() => handler()}
        onCancel={closeHandler}
        width="600px"
        footer={null}
      >
        <Text type="secondary">
          Run the schedule on a repeated cycle time to update this data source
        </Text>
        <Select placeholder="Select the interval" width="100%">
          <Select.Option value="30min">Run every 30 minutes</Select.Option>
          <Select.Option value="60min">Run every 60 minutes</Select.Option>
          <Select.Option value="6hours">Run every 6 hours</Select.Option>
          <Select.Option value="12hours">Run every 12 hours</Select.Option>
          <Select.Option value="1day">every day(24 hours)</Select.Option>
        </Select>
        <br />
        <br />

        <Button icon={<MdSchedule />} scale={2 / 3} type="secondary">
          Schedule now
        </Button>
      </Modal>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => handler()}
      >
        <MdSchedule />

        <span
          style={{
            fontSize: 13,
            marginLeft: 5,
          }}
        >
          Schedule the update
        </span>
      </div>
    </>
  )
}

export default SyncAndUpdateData
