import { Button, Input, Text } from '@geist-ui/core'
import { Form, message } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'

const AirtableDataForm = ({
  projectId,
  triggerOnUpload,
  closeDataSourceModal,
}) => {
  const [validateStatus, setValidateStatus] = useState(true)
  const [addingAirtableKeys, setAddingAirtableKeys] = useState(false)

  const [baseIdValue, setBaseIdValue] = useState('')
  const [tableIdValue, setTableIdValue] = useState('')
  const [viewIdValue, setViewIdValue] = useState('')
  const onFinish = async values => {
    setAddingAirtableKeys(true)
    const { apiKey, airtableBaseUrl, fileName } = values

    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${apiKey}`)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    }

    fetch(
      `https://api.airtable.com/v0/${baseIdValue}/${tableIdValue}?maxRecords=3&view=${viewIdValue}`,
      requestOptions,
    )
      .then(response => response.text())
      .then(result => {
        const { error, records } = JSON.parse(result)
        if (error)
          return message.error(`${error.message} — Invalid Airtable API key`)

        const keyObjs = {
          apiKey,
          baseIdValue,
          tableIdValue,
          viewIdValue,
          airtableBaseUrl,
          projectId,
          fileName,
        }

        var config = {
          method: 'post',
          url: `${process.env.GATSBY_API_BASE_URL}/addAirtableDataSource`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(keyObjs),
        }

        axios(config)
          .then(function (response) {
            setAddingAirtableKeys(false)
            triggerOnUpload()
            closeDataSourceModal()
          })
          .catch(function (error) {
            setAddingAirtableKeys(false)
          })
      })
      .catch(error => {
        setAddingAirtableKeys(false)
      })
  }

  return (
    <Form
      name="airtableDataForm"
      initialValues={{}}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        name="apiKey"
        extra={
          <Text small>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  'Go to Airtable Account → <a href="https://airtable.com/account" target="_blank">Airtable API Key</a>',
              }}
            />
          </Text>
        }
      >
        <Input.Password width="100%" placeholder="Airtable API Key" required />
      </Form.Item>
      <Form.Item
        name="airtableBaseUrl"
        extra={
          <Text small style={{ fontWeight: 300 }}>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  'The Airtable Base URL should look like this ↓ <br/> https://airtable.com/appYXi3VzuWmFYUy0/tblVhBGlHGk6gKqW2/viwgPJqV6YPuMoZDf',
              }}
            />
          </Text>
        }
        validateStatus={validateStatus}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              try {
                const url = new URL(value)
                const { hostname, pathname } = url
                const [, baseId, tableId, viewId] = pathname.split('/')
                const baseIdCharCheck = baseId.substring(0, 3) === 'app'
                const tableIdCharCheck = tableId.substring(0, 3) === 'tbl'
                const viewIdCharCheck = viewId.substring(0, 3) === 'viw'
                if (
                  hostname === 'airtable.com' &&
                  baseIdCharCheck &&
                  tableIdCharCheck &&
                  viewIdCharCheck
                ) {
                  setBaseIdValue(baseId)
                  setTableIdValue(tableId)
                  setViewIdValue(viewId)
                  setValidateStatus('')
                  return Promise.resolve()
                }
                message.error('Input Airtable base URL is not valid base URL')
                setValidateStatus('error')
              } catch (error) {
                message.error('Input Airtable base URL is not valid base URL')
              }
            },
          }),
        ]}
      >
        <Input
          width="100%"
          placeholder="Airtable Base URL"
          required
          //   onChange={parseBaseURL}
        />
      </Form.Item>
      <Form.Item
        name="fileName"
        extra={
          <Text small>
            <span
              dangerouslySetInnerHTML={{
                __html: 'Give a name to this data source',
              }}
            />
          </Text>
        }
      >
        <Input width="100%" placeholder="File name" required />
      </Form.Item>

      <Form.Item>
        <Button
          type="secondary"
          scale={2 / 3}
          htmlType="submit"
          loading={addingAirtableKeys}
        >
          Validate Airtable input & Save
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AirtableDataForm
