import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Empty } from 'antd'

import { AppstoreAddOutlined } from '@ant-design/icons'
import { AiOutlineRight } from 'react-icons/ai'
import axios from 'axios'
import moment from 'moment'
import { Button } from '@geist-ui/core'

const ProjectList = ({ uid }) => {
  const [projectList, setProjectList] = useState([])

  useEffect(() => {
    if (uid !== '') {
      var config = {
        method: 'post',
        url: `${process.env.GATSBY_API_BASE_URL}/projects`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({ uid }),
      }

      axios(config)
        .then(function (response) {
          const { data } = response
          setProjectList(data)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [uid])
  return (
    <>
      <div className="forms-list">
        {projectList.length > 0 ? (
          <div className="project-list">
            {projectList.map((project, key) => (
              <Link
                to={`/projects/${project.projectUniqueId}`}
                key={key}
                className="project-card"
              >
                <div className="card-inner">
                  <div className="card-head">
                    <div className="name">{project.projectName}</div>
                    <AiOutlineRight />
                  </div>
                  <small className="timezone">
                    {moment(project.created_date).fromNow()}
                  </small>
                  <br />
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <Empty
            style={{ color: '#555' }}
            description="Welcome here kind stranger. Let's set you up."
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          >
            <Link to="/create">
              <Button
                type="secondary"
                icon={<AppstoreAddOutlined />}
                width="240px"
              >
                Create my first project
              </Button>
            </Link>
          </Empty>
        )}
      </div>
    </>
  )
}

export default ProjectList
