import React, { useState } from 'react'

import { Button, Fieldset, Snippet, Text } from '@geist-ui/core'
import axios from 'axios'
import { Modal, message } from 'antd'
import { CrownOutlined } from '@ant-design/icons'

const ProjectSettings = ({ projectInfo }) => {
  const [deleting, setDeleting] = useState(false)
  const [state, setState] = useState(false)
  const handler = () => setState(true)
  const closeHandler = event => {
    setState(false)
  }
  const userName = projectInfo?.mongoUserInfo?.userName
  const userPassword = projectInfo?.mongoUserInfo?.userPassword

  const deleteThisProject = async () => {
    setDeleting(true)
    try {
      let response = await axios.delete(
        `${process.env.GATSBY_API_BASE_URL}/removeProject/${projectInfo.projectUniqueId}`,
      )
      setDeleting(false)
      localStorage.removeItem('activeProject')
      message.success('Successfully deleted.')
      window.location.href = '/projects'
    } catch (error) {
      message.error('Something is wrong')
      setDeleting(false)
    }
  }
  return (
    <>
      <div className="forms-list">
        <Modal
          title="Delete this project"
          visible={state}
          onCancel={closeHandler}
          footer={null}
        >
          <Text>Are you sure?</Text>
          <Text type="secondary">
            This will delete the server, database, and all the users data of
            your server.
          </Text>

          <Button onClick={() => deleteThisProject()}>Yes, Delete this.</Button>
        </Modal>
        <Fieldset>
          <Fieldset.Title>Custom Domain</Fieldset.Title>
          <Fieldset.Subtitle>
            Request to setup custom domain for this project.{' '}
            <a
              href={`mailto:hello@quickapi.io?subject=Custom Domain Request&body=Hi, I want to point the project ${projectInfo.projectHostName} to my custom domain - `}
              target="_blank"
            >
              Email us → hello@quickapi.io
            </a>{' '}
            and we will setup it within 6 hours.
          </Fieldset.Subtitle>
        </Fieldset>

        {/* <Fieldset>
                              <Fieldset.Title>
                                    Access your data via Mongo Compass
                              </Fieldset.Title>
                              <Fieldset.Subtitle>
                                    You have full ownership to access your data
                                    on your machine via Mongo Client(Mongo
                                    Compass).
                              </Fieldset.Subtitle>

                              {!projectInfo.paidProject ? (
                                    <>
                                          {userName !== undefined &&
                                          userPassword !== undefined ? (
                                                <>
                                                      <Snippet
                                                            text={`mongodb://${userName}:${userPassword}@localhost:27017/db${projectInfo.projectUniqueId}`}
                                                            width="100%"
                                                      />
                                                      <Text
                                                            type="secondary"
                                                            small
                                                            style={{
                                                                  textDecoration:
                                                                        'underline',
                                                            }}
                                                      >
                                                            Watch the tutorial,
                                                            how to connect
                                                      </Text>
                                                </>
                                          ) : (
                                                <Alert
                                                      message="This feature works only
                                          for new projects. Create
                                          a new project."
                                                      type="error"
                                                      showIcon
                                                      style={{ fontSize: 13 }}
                                                />
                                          )}
                                    </>
                              ) : (
                                    <Alert
                                          message="You Need to upgrade the plan for this Mongo Connect feature."
                                          type="success"
                                          icon={<CrownOutlined />}
                                          showIcon
                                          style={{ fontSize: 13 }}
                                    />
                              )}
                        </Fieldset> */}

        <br />
        <Fieldset>
          <Fieldset.Title>Delete this project</Fieldset.Title>
          <Fieldset.Subtitle>
            This will delete the server, database, and all the users data of
            your server.
          </Fieldset.Subtitle>
          <Fieldset.Footer>
            <Text
              type="error"
              style={{
                marginTop: '1em',
              }}
            >
              This action cannot be undone
            </Text>
            <Button
              auto
              scale={1 / 3}
              type="error"
              font="12px"
              onClick={handler}
              loading={deleting}
            >
              Delete this project
            </Button>
          </Fieldset.Footer>
        </Fieldset>
      </div>
    </>
  )
}

export default ProjectSettings
