import { Button, Loading, Note, Table, Text } from '@geist-ui/core'
import { DownOutlined } from '@ant-design/icons'

import React, { useEffect, useState } from 'react'
import { Dropdown, Menu, message, Space, Modal, Empty } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { BiDotsVertical } from 'react-icons/bi'

const UsersTable = ({ uid, projectId, publicPageObj }) => {
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)
  const [refreshTableData, setRefreshTableData] = useState(0)
  const [state, setState] = useState(false)
  useEffect(() => {
    var data = JSON.stringify({ uid, projectId })
    setLoading(true)

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/getUsers`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        setUserData(response.data)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
      })
  }, [refreshTableData])

  const renderCreatedDate = (value, rowData, index) => {
    return moment(value).fromNow()
  }
  const renderUserAPIcalls = (value, rowData, index) => {
    return (
      <Text type="secondary" style={{ margin: 0 }}>
        {rowData.consumed}/{rowData.apiCalls}
      </Text>
    )
  }

  const handleUserStatus = rowDataObj => {
    const statusToChange =
      rowDataObj.status === 'enabled' ? 'disabled' : 'enabled'
    var data = JSON.stringify({
      uid: rowDataObj.uid,
      projectId,
      status: statusToChange,
    })

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/disableEnableUser`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        setRefreshTableData(refreshTableData + 1)
        setLoading(false)
        message.info(`account ${rowDataObj.email} is ${statusToChange}`)
      })
      .catch(function (error) {
        setLoading(false)
      })
  }

  const deleteThisUser = rowDataObj => {
    var data = JSON.stringify({
      uid: rowDataObj.uid,
      projectId,
    })

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/deleteEndUser`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        setRefreshTableData(refreshTableData + 1)
        setState(false)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
      })
  }

  const renderUserActions = (value, rowData, index) => {
    return (
      <Dropdown
        overlay={
          <Menu
            items={[
              {
                key: '1',
                label: (
                  <span
                    onClick={() => handleUserStatus(rowData)}
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {rowData.status === 'enabled' ? 'Disable' : 'Enable'}{' '}
                    Account
                  </span>
                ),
              },
              {
                key: '4',
                danger: true,
                label: (
                  <>
                    <span
                      onClick={e => {
                        e.preventDefault()
                        setState(true)
                      }}
                      style={{
                        fontSize: 12,
                      }}
                    >
                      Delete Account
                    </span>
                    <Modal
                      title="Delete user"
                      open={state}
                      onCancel={() => setState(false)}
                      footer={null}
                    >
                      <Note type="error">
                        After you delete an account, it's permanently deleted.
                        Accounts can't be undeleted.
                      </Note>
                      <br />
                      <Text h3>User account</Text>
                      <Text type="secondary">{rowData.email}</Text>
                      <Button
                        scale={2 / 3}
                        onClick={() => deleteThisUser(rowData)}
                      >
                        Delete
                      </Button>
                    </Modal>
                  </>
                ),
              },
            ]}
          />
        }
      >
        <a onClick={e => e.preventDefault()}>
          <Space>
            Manage
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    )
  }

  return !loading ? (
    <div>
      {userData.length > 0 ? (
        <Table data={userData}>
          <Table.Column prop="email" label="Email address" />
          <Table.Column
            prop="created_date"
            label="Signup date"
            render={renderCreatedDate}
          />
          <Table.Column prop="country" label="Country" />
          <Table.Column prop="plan" label="Plan" />
          <Table.Column
            render={renderUserAPIcalls}
            prop="apiCalls"
            label="API Usage"
          />
          <Table.Column prop="agent" label="Browser" />
          <Table.Column
            label="Actions"
            prop="status"
            render={renderUserActions}
            width={50}
          />
        </Table>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="This project don't have any active user."
        />
      )}
    </div>
  ) : (
    <Loading />
  )
}

export default UsersTable
