import React from 'react'
import { FaFileCsv } from 'react-icons/fa'
import { SiAirtable, SiGooglesheets } from 'react-icons/si'
import { TbApi } from 'react-icons/tb'

const renderDataType = (value, text) => {
  if (value === 'csv')
    return (
      <div className="typeHtml">
        <FaFileCsv /> <span>{text ?? 'CSV'}</span>
      </div>
    )
  else if (value === 'airtable')
    return (
      <div className="typeHtml">
        <SiAirtable /> <span>{text ?? 'Airtable'}</span>
      </div>
    )
  else if (value === 'sheet')
    return (
      <div className="typeHtml">
        <SiGooglesheets /> <span>{text ?? 'Google Sheet'}</span>
      </div>
    )
  else if (value === 'api')
    return (
      <div className="typeHtml">
        <TbApi /> <span>{text ?? 'API'}</span>
      </div>
    )
  else return value
}

export default renderDataType
