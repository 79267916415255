import { Card, Grid, Text, Tooltip } from '@geist-ui/core'
import { Modal } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GrStripe } from 'react-icons/gr'
import { FaRegBuilding } from 'react-icons/fa'
import Checkout from '../dashboard/checkout'

import '../../styles/pricing.scss'
import { pricingOptions } from '../../data/pricing'

const ActiveProjectUsage = ({ activeProjectId, uid }) => {
  const [usageObj, setUsageObj] = useState(null)
  useEffect(() => {
    if (!activeProjectId || !uid) return

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/projectUsage/${activeProjectId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ uid }),
    }

    axios(config)
      .then(function (response) {
        const { data } = response
        setUsageObj(data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [uid, activeProjectId])

  const handler = () => {
    window.$crisp.push([
      'set',
      'message:text',
      ["Hey, I'm looking to upgrade my plan."],
    ])
    window.$crisp.push(['do', 'chat:open'])
  }

  return (
    <>
      {usageObj !== null && (
        <>
          <div id="progressbar">
            <div
              style={{
                width: usageObj.consumedPercentage + '%',
              }}
            ></div>
          </div>

          <Tooltip
            placement="bottom"
            text={
              <Text small>
                {`This project consumed ${usageObj.consumedPercentage}% (${usageObj.usageData.count} / ${usageObj.usageData.reqLimits})`}
              </Text>
            }
          >
            <Text
              type="secondary"
              style={{
                display: 'inline',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => handler()}
            >
              {`${usageObj.consumedPercentage}% (${usageObj.usageData.count}/${usageObj.usageData.reqLimits}) — Upgrade plan`}
            </Text>
          </Tooltip>
        </>
      )}
    </>
  )
}

export default ActiveProjectUsage
