import { Button, Fieldset, Input, Select } from '@geist-ui/core'
import { Form, message } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BsSave } from 'react-icons/bs'

const AnalyticsSetting = ({ projectInfo, triggerRefresh }) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {}, [])

  const addGAKey = values => {
    const { projectUniqueId } = projectInfo
    var data = JSON.stringify({
      id: projectUniqueId,
      bodyData: values,
      flag: 'integration',
    })

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/projects/${projectUniqueId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        const { error } = response.data
        if (error !== undefined)
          message.info('Your Stripe Secret Key is not valid')

        triggerRefresh()
        message.success('Settings updated')
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div className="plans-customize">
      <Fieldset style={{ border: 0 }} className="fieldset-zero-padding">
        <Fieldset.Title>Integrations</Fieldset.Title>
        <Fieldset.Subtitle>
          Add analytics tool, chat support, & more.
        </Fieldset.Subtitle>
      </Fieldset>
      <br />
      <Form
        name="stripeKey"
        initialValues={projectInfo.integration}
        onFinish={addGAKey}
        autoComplete="off"
        layout="vertical"
      >
        {/* <Form.Item
                              label="Analytics tool"
                              name="analyticsToolType"
                        >
                              <Select placeholder="Choose tool">
                                    <Select.Option value="ga">
                                          Google Analytics
                                    </Select.Option>
                                    <Select.Option value="sa">
                                          Simple Analytics
                                    </Select.Option>
                                    <Select.Option value="mixpanel">
                                          Mixpanel
                                    </Select.Option>
                                    <Select.Option value="fantom">
                                          Fathom Analytics
                                    </Select.Option>
                              </Select>
                        </Form.Item>
                        <Form.Item label="Tracking code" name="trackingCode">
                              <Input
                                    placeholder="Your unique code."
                                    width="100%"
                              />
                        </Form.Item> */}
        <Form.Item label="Chat" name="chatToolType">
          <Select placeholder="Choose chat service">
            <Select.Option value="crisp">Crisp</Select.Option>
            <Select.Option value="intercom">Intercom</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="App ID" name="chatAppId">
          <Input placeholder="Your unique app id." width="100%" />
        </Form.Item>
        <Form.Item>
          <Button
            type="secondary"
            htmlType="submit"
            loading={loading}
            width="170px"
            icon={<BsSave />}
          >
            Save settings
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AnalyticsSetting
