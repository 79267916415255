import { Button } from '@geist-ui/core'
import { Form, message, Input } from 'antd'
import axios from 'axios'
import { AiOutlineStop } from 'react-icons/ai'
import { RiInformationLine } from 'react-icons/ri'
import { RiBuilding2Line } from 'react-icons/ri'
import React, { useState } from 'react'
import { HiSave } from 'react-icons/hi'
import { RiFlashlightFill } from 'react-icons/ri'
import { VscLinkExternal } from 'react-icons/vsc'

const { TextArea } = Input

const PageConstomize = ({ projectInfo, triggerRefresh }) => {
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('info')
  const updatePublicPage = values => {
    let { publicPageObj, projectUniqueId } = projectInfo
    if (!values.keywords) values.keywords = ''
    if (!values.description) values.description = ''

    Object.keys(values).forEach(function (key) {
      if (key === 'freeLimit') {
        publicPageObj[key] = parseInt(values[key])
      } else {
        publicPageObj[key] = values[key]
      }
    })

    var data = JSON.stringify({
      id: projectUniqueId,
      bodyData: publicPageObj,
      flag: 'publicpage',
    })

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/projects/${projectUniqueId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        const { error } = response.data

        if (error) {
          message.info(error)
        } else {
          message.success('Your page is updated.')
          triggerRefresh()
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div className="page-customize">
      <div className="page-editor">
        <div className="editor-nav">
          <div
            className={
              activeTab === 'info'
                ? 'editor-nav-item active-tab'
                : 'editor-nav-item'
            }
            onClick={() => setActiveTab('info')}
          >
            <RiInformationLine />
            <span>Page Information</span>
          </div>
          <div
            className={
              activeTab === 'seo'
                ? 'editor-nav-item active-tab'
                : 'editor-nav-item'
            }
            onClick={() => setActiveTab('seo')}
          >
            <RiFlashlightFill />
            <span>SEO</span>
          </div>
          <div
            className={
              activeTab === 'company'
                ? 'editor-nav-item active-tab'
                : 'editor-nav-item'
            }
            onClick={() => setActiveTab('company')}
          >
            <RiBuilding2Line />
            <span>About company</span>
          </div>
        </div>
        <div className="editor-form">
          <Form
            name="basic"
            initialValues={projectInfo.publicPageObj}
            onFinish={updatePublicPage}
            autoComplete="off"
            layout="vertical"
          >
            {activeTab === 'info' && (
              <>
                <Form.Item
                  label="Product Name"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="Product Name"
                    width="100%"
                    type="secondary"
                  />
                </Form.Item>

                <Form.Item
                  label="Tagline"
                  name="projectTagline"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="Short tagline"
                    width="100%"
                    type="secondary"
                  />
                </Form.Item>
              </>
            )}
            {activeTab === 'seo' && (
              <>
                <Form.Item label="Description" name="aboutProject">
                  <TextArea
                    placeholder="More about project"
                    width="100%"
                    type="secondary"
                  />
                </Form.Item>
                <Form.Item label="Keywords" name="keywords">
                  <Input
                    placeholder="Keywords by seprated commas"
                    width="100%"
                    type="secondary"
                  />
                </Form.Item>
              </>
            )}
            {activeTab === 'limit' && (
              <>
                <Form.Item
                  label="API limit for free users"
                  name="freeLimit"
                  rules={[
                    {
                      required: true,
                      message: 'A value must be entered',
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  extra="To remove the free plan, set the limit to 0"
                >
                  <Input
                    placeholder="Free API limit per month"
                    width="100%"
                    type="secondary"
                  />
                </Form.Item>
              </>
            )}

            {activeTab === 'company' && (
              <>
                <Form.Item
                  label="Company name"
                  name="companyName"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="Input company name"
                    width="100%"
                    type="secondary"
                  />
                </Form.Item>
                <Form.Item
                  label="Terms"
                  name="termsLink"
                  rules={[{ required: true }]}
                  extra="Your public notion page for terms."
                >
                  <Input
                    placeholder="Link to your terms page"
                    width="100%"
                    type="secondary"
                  />
                </Form.Item>
                <Form.Item
                  label="Privacy & Policy Link"
                  name="privacyLink"
                  rules={[{ required: true }]}
                  extra="Your public notion page for Privacy &
                              Policy."
                >
                  <Input
                    placeholder="Link to your Privacy & Policy page"
                    width="100%"
                    type="secondary"
                  />
                </Form.Item>
                <Form.Item
                  label="Contact Email"
                  name="contactEmail"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="Company contact email address"
                    width="100%"
                    type="secondary"
                  />
                </Form.Item>{' '}
              </>
            )}

            <Form.Item>
              <Button
                type="secondary"
                htmlType="submit"
                loading={loading}
                icon={<HiSave />}
                width="160px"
                scale={2 / 3}
              >
                Save changes
              </Button>
              &nbsp; &nbsp; &nbsp;
              <a href={projectInfo.projectHostName} target="_blank">
                <Button
                  icon={<VscLinkExternal />}
                  disabled={projectInfo.publicPageObj === null ? true : false}
                  scale={2 / 3}
                >
                  Page preview
                </Button>
              </a>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default PageConstomize
