import { Button, Input, Select, Text } from '@geist-ui/core'
import { Form, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import SheetInfo from '../sheetInfo'

const SheetDataForm = ({
  projectId,
  triggerOnUpload,
  uid,
  closeDataSourceModal,
}) => {
  const [authList, setAuthList] = useState([])

  const [activeAccountId, setActiveAccountId] = useState(null)
  const [activeKeyObj, setActiveKeyObj] = useState(null)
  const [triggerActiveComp, setTriggerActiveComp] = useState(0)
  const [loadingAuth, setLoadingAuth] = useState(false)
  let windowObjectReference = null
  let previousUrl = null

  useEffect(() => {
    setLoadingAuth(true)
    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/auth/lists`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ projectId, uid }),
    }

    axios(config)
      .then(function (response) {
        setLoadingAuth(false)
        setAuthList(response.data)
      })
      .catch(function (error) {
        setLoadingAuth(false)
      })
  }, [triggerActiveComp])

  let isListenerAttached = true

  const receiveMessage = event => {
    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    const { data } = event

    if (event.origin !== process.env.GATSBY_APP_BASE_URL) {
      return
    }

    const { appId, code } = data
    if (appId === 'qa-oauth-app' && code !== undefined && isListenerAttached) {
      isListenerAttached = false

      axios
        .post(
          `${process.env.GATSBY_API_BASE_URL}/auth/google`,
          {
            code,
            uid,
            projectId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(response => {
          setTimeout(() => {
            isListenerAttached = true
          }, 5000)
          setTriggerActiveComp(triggerActiveComp + 1)
        })
    }
    // const redirectUrl = `/auth/google/login${payload}`;
    // window.location.pathname = redirectUrl;
  }

  const openSignInWindow = (url, name) => {
    // remove any existing event listeners
    window.removeEventListener('message', receiveMessage)

    // window features
    const strWindowFeatures =
      'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'

    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
               or if such pointer exists but the window was closed */
      windowObjectReference = window.open(url, name, strWindowFeatures)
    } else if (previousUrl !== url) {
      /* if the resource to load is different,
               then we load it in the already opened secondary window and then
               we bring such window back on top/in front of its parent window. */
      windowObjectReference = window.open(url, name, strWindowFeatures)
      windowObjectReference.focus()
    } else {
      /* else the window reference must exist and the window
               is not closed; therefore, we can bring it back on top of any other
               window with the focus() method. There would be no need to re-create
               the window or to reload the referenced resource. */
      windowObjectReference.focus()
    }

    // add the listener for receiving a message from the popup
    window.addEventListener('message', event => receiveMessage(event), false)
    // assign the previous URL
    previousUrl = url
  }

  const handler = val => {
    message.info('Loading your spreadsheets...')
    const accountIndex = authList.findIndex(x => x._id === val)
    setActiveKeyObj(authList[accountIndex])
    setActiveAccountId(val)
  }

  return (
    <>
      {/* <Button
                        onClick={() =>
                              openSignInWindow(
                                    `${process.env.GATSBY_API_BASE_URL}/auth/google`,
                                    'qa-google-auth'
                              )
                        }
                  >
                        <img
                              src="/google-logo.png"
                              alt="google login"
                              height="25px"
                              width="25px"
                              style={{
                                    marginRight: 15,
                              }}
                        />
                        <span>Sign in with Google</span>
                  </Button> */}
      {authList.length === 0 ? (
        <Button
          onClick={() =>
            openSignInWindow(
              `${process.env.GATSBY_API_BASE_URL}/auth/google`,
              'qa-google-auth',
            )
          }
          loading={loadingAuth}
        >
          <img
            src="/google-logo.png"
            alt="google login"
            height="25px"
            width="25px"
            style={{
              marginRight: 15,
            }}
          />
          <span>Sign in with Google</span>
        </Button>
      ) : (
        <>
          <Select
            placeholder="Select your google account"
            onChange={handler}
            width="100%"
          >
            {authList.map((acc, key) => (
              <Select.Option value={acc._id}>
                {' '}
                <img
                  src={acc.picture}
                  alt={acc.name}
                  height="20px"
                  width="20px"
                  style={{
                    marginRight: 15,
                    borderRadius: '50%',
                  }}
                />
                <span>
                  {acc.name} — {moment(acc.updated_date).fromNow()}
                </span>
              </Select.Option>
            ))}
          </Select>

          {activeAccountId !== null && (
            <SheetInfo
              accountId={activeAccountId}
              projectId={projectId}
              triggerOnUpload={triggerOnUpload}
              keyObj={activeKeyObj}
              closeDataSourceModal={closeDataSourceModal}
            />
          )}
        </>
      )}
    </>
  )
}

export default SheetDataForm
