import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { message } from 'antd'
import { Button, Input, Select } from '@geist-ui/core'

const SheetInfo = ({
  accountId,
  projectId,
  triggerOnUpload,
  keyObj,
  closeDataSourceModal,
}) => {
  const [driveFiles, setDriveFiles] = useState([])
  const [sheetId, setSheetId] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [selectedTab, setSelectedTab] = useState(null)
  const [sheetTabs, setSheetTabs] = useState([])
  const [activeTabId, setActiveTabId] = useState('')
  const [addingSheetKeys, setAddingSheetKeys] = useState(false)

  useEffect(() => {
    async function fetchAccountList() {
      setDriveFiles([])
      setSheetTabs([])
      var data = JSON.stringify({
        _id: accountId,
      })

      const response = await axios.post(
        `${process.env.GATSBY_API_BASE_URL}/auth/listDriveFiles`,
        data,
        { headers: { 'Content-Type': 'application/json' } },
      )
      setDriveFiles(response.data)
    }
    if (accountId !== '') fetchAccountList()
  }, [accountId])

  const onFileChange = async value => {
    message.info('Getting tabs...')
    setSelectedTab(null)
    setSheetId(value)
    setSheetTabs([])
    var data = JSON.stringify({
      _id: accountId,
      sheetId: value,
    })

    const response = await axios.post(
      `${process.env.GATSBY_API_BASE_URL}/auth/listOfTabs`,
      data,
      { headers: { 'Content-Type': 'application/json' } },
    )
    setSheetTabs(response.data)
  }

  const onTabsChange = async value => {
    setSelectedTab(value)
    setActiveTabId(value)
  }

  const saveSheetDatasource = () => {
    setAddingSheetKeys(true)
    const keyObjs = {
      projectId,
      accountId,
      fileName,
      sheetId,
      selectedTab,
      oauthKeys: keyObj.auth_keys,
    }

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/addSheetDataSource`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(keyObjs),
    }

    axios(config)
      .then(function (response) {
        setAddingSheetKeys(false)
        triggerOnUpload()
        closeDataSourceModal()
      })
      .catch(function (error) {
        setAddingSheetKeys(false)
      })
  }

  return (
    <div>
      <br />
      {driveFiles.length > 0 && (
        <Select
          placeholder="Choose Spreadsheet account"
          onChange={onFileChange}
          width="100%"
        >
          {driveFiles.map((file, key) => (
            <Select.Option value={file.id} key={key}>
              {file.name}
            </Select.Option>
          ))}
        </Select>
      )}
      <br />
      <br />
      {sheetTabs.length > 0 && (
        <Select
          placeholder="Choose sheet Tab Id"
          onChange={onTabsChange}
          width="100%"
        >
          {sheetTabs.map((tab, key) => (
            <Select.Option value={tab.title} key={key}>
              {tab.title}
            </Select.Option>
          ))}
        </Select>
      )}
      <br />
      <br />
      {selectedTab !== null && (
        <Input
          width="100%"
          placeholder="Give a name to this data source"
          onChange={e => setFileName(e.target.value)}
        />
      )}
      <br />
      <br />
      {fileName !== null && (
        <Button
          type="secondary"
          scale={2 / 3}
          loading={addingSheetKeys}
          onClick={() => saveSheetDatasource()}
        >
          Save the data source
        </Button>
      )}
    </div>
  )
}

export default SheetInfo
