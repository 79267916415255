import React, { useState, useEffect } from 'react'
import { Empty, message, Modal, Tag, Tooltip } from 'antd'
import axios from 'axios'
import SetupEndpoint from './setupEndpoint'
import { Description, Loading, Text } from '@geist-ui/core'
import EditEndpoint from './editEndpoint'
import ConditionalWrapper from '../../components/ConditionalWrapper'
import { Edit, PlayFill, Trash2 } from '@geist-ui/icons'

const EndpointConfig = ({ id, projectInfo }) => {
  const [loading, setLoading] = useState(false)
  const [endpointList, setEndpointList] = useState([])
  const [dataSourceList, setDataSourceList] = useState([])
  const [showEditDrawer, setShowEditDrawer] = useState(false)
  const [thiEnObj, setThiEnObj] = useState(null)
  const { adminKey } = projectInfo

  const [triggerPage, setTriggerPage] = useState(0)
  useEffect(() => {
    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/getProjectEndpoints`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        projectId: id,
      }),
    }
    setLoading(true)
    axios(config)
      .then(function (response) {
        setEndpointList(response.data.endpoints)
        setDataSourceList(response.data.csvData)
      })
      .catch(function (error) {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }, [triggerPage])

  const endpointName = (enp, type = '') => {
    return (
      <a
        href={`${enp}?adminKey=${adminKey}`}
        style={{ color: '#000', textDecoration: 'underline' }}
        target="_blank"
      >
        <ConditionalWrapper
          wrapper={children => (
            <Tooltip
              style={{
                display: type == 'ALL' ? 'initial' : 'none',
              }}
              popupVisible={type == 'ALL' ? true : false}
              title="Accept all HTTP methods (GET, POST, ...)"
            >
              {children}
            </Tooltip>
          )}
          condition={type == 'ALL'}
        >
          <Tag
            color={type ? EndpointColors[type.toUpperCase()] : 'magenta'}
            style={{ fontSize: 10, width: 40, textAlign: 'center' }}
          >
            {type ? type.toUpperCase() : 'GET'}
          </Tag>
        </ConditionalWrapper>
        <span>{enp}</span>
      </a>
    )
  }

  const deleteThisEndpoint = en => {
    if (window.confirm('Are you sure you want to delete this endpoint?')) {
      var config = {
        method: 'post',
        url: `${process.env.GATSBY_API_BASE_URL}/deleteProjectEndpoint`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          _id: en._id,
          projectId: en.projectId,
        }),
      }

      axios(config)
        .then(function (response) {
          message.success('The endpoint is deleted.')
          setTriggerPage(triggerPage + 1)
        })
        .catch(function (error) {
          message.error('There are some errors.')
        })
    } else {
      // Do nothing!
      console.log('Ok sir.')
    }
  }

  const getEndpointMethod = endpoint => {
    const source = dataSourceList.find(s => s._id == endpoint.dataSource)
    if (source?.dataSourceType == 'api') return 'ALL'
    else return endpoint.method
  }

  return (
    <div>
      {loading ? (
        <Loading />
      ) : endpointList.length > 0 ? (
        <>
          <div className="data-source-head">
            <div className="project-info">
              <Description
                title="List of endpoints"
                content={`Total endpoint — ${endpointList.length}`}
              />
            </div>
            <SetupEndpoint
              id={id}
              projectInfo={projectInfo}
              dataSourceList={dataSourceList}
              triggerPage={() => setTriggerPage(triggerPage + 1)}
            />
          </div>
          <EditEndpoint
            showEditDrawer={showEditDrawer}
            handleEditDrawer={() => {
              setShowEditDrawer(false)
              setThiEnObj(null)
            }}
            enObj={thiEnObj}
            id={id}
            projectInfo={projectInfo}
            triggerPage={() => setTriggerPage(triggerPage + 1)}
            dataSourceList={dataSourceList}
            endpointList={endpointList}
          />

          {endpointList.map((en, key) => (
            <div className="endpoint-item">
              <div className="en-content">
                <div className="en-description">
                  <Text small type="secondary">
                    → {en.aboutPath}
                  </Text>
                </div>
                <div className="en-tab">
                  <div className="en-string">
                    {endpointName(
                      `${projectInfo.projectHostName}/${en.path}`,
                      getEndpointMethod(en),
                    )}
                  </div>
                  <div className="en-actions">
                    <a
                      href={`${projectInfo.projectHostName}/${en.path}?adminKey=${adminKey}`}
                      target="_blank"
                      style={{
                        color: '#242424',
                        marginBottom: -6,
                      }}
                    >
                      <PlayFill />
                    </a>
                    <Edit
                      onClick={() => {
                        setThiEnObj(en)
                        setShowEditDrawer(!showEditDrawer)
                      }}
                    />
                    <Trash2 onClick={() => deleteThisEndpoint(en)} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{
            height: 60,
          }}
          description={<span>There is no endpoint.</span>}
        >
          <SetupEndpoint
            id={id}
            projectInfo={projectInfo}
            dataSourceList={dataSourceList}
            triggerPage={() => setTriggerPage(triggerPage + 1)}
          />
        </Empty>
      )}
    </div>
  )
}

export default EndpointConfig

export const EndpointColors = {
  GET: 'magenta',
  POST: 'green',
  PUT: 'blue',
  DELETE: 'red',
  PATCH: 'orange',
  OPTIONS: 'cyan',
  HEAD: 'geekblue',
  CONNECT: 'purple',
  ALL: 'volcano',
}
