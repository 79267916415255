import React, { useState } from 'react'
import { Select, Text, Button } from '@geist-ui/core'
import { Form, Modal, message } from 'antd'
import { CgDatabase } from 'react-icons/cg'
import axios from 'axios'

const CreateIndex = ({ rowData, id }) => {
  const { dataSourceType } = rowData
  const [form] = Form.useForm()
  const [state, setState] = useState(false)
  const [activeSelectedName, setActiveSelectedName] = useState('')
  const [addedIndexFields, setAddedIndexFields] = useState([])
  const [indexFieldsArray, setIndexFieldsArray] = useState([])
  const handler = () => setState(true)
  const closeHandler = event => {
    setState(false)
    console.log('closed')
  }

  const { activeCollectionName, objShortKey } = rowData

  const createIndex = val => {
    delete val._id
    var data = JSON.stringify({
      ...val,
      activeCollectionName: activeSelectedName,
      projectId: id,
    })

    var config = {
      method: 'post',
      url: `${process.env.GATSBY_API_BASE_URL}/createIndexFields`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        message.success(
          `Indexes created successfully, now you can make search api endpoint with this datasource`,
        )
        setState(false)
      })
      .catch(function (error) {
        message.error('somehing is not working, talk in the chat.')
      })
  }
  return (
    <>
      <Modal
        title="Update Search Index"
        open={state}
        onOk={() => handler()}
        onCancel={closeHandler}
        width="600px"
        footer={null}
      >
        <Text type="secondary">
          If you wants to enable a search on this dataset then you have to do
          this.
        </Text>
        <Form
          name="basic"
          form={form}
          onFinish={createIndex}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            activeCollectionName: activeSelectedName,
          }}
        >
          <Form.Item
            label="Select index fields"
            name="indexFields"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Select
              multiple
              placeholder="Choose one"
              width="100%"
              initialValue={addedIndexFields}
            >
              {indexFieldsArray.map((fieldKey, key) => (
                <Select.Option value={fieldKey} key={key}>
                  {fieldKey}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="secondary" htmlType="submit" scale={1 / 2}>
              Update index fields
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => {
          const { activeCollectionName, projectId } = rowData
          if (activeCollectionName !== null) {
            var data = JSON.stringify({
              projectId,
              activeCollectionName,
            })

            var config = {
              method: 'post',
              url: `${process.env.GATSBY_API_BASE_URL}/getIndexFields`,
              headers: {
                'Content-Type': 'application/json',
              },
              data: data,
            }

            axios(config)
              .then(function (response) {
                setActiveSelectedName(activeCollectionName)
                setState(true)
                const { indexFieldList, singleObj } = response.data
                form.setFieldsValue({
                  indexFields: indexFieldList,
                })
                setIndexFieldsArray(Object.keys(singleObj))
                setAddedIndexFields(indexFieldList)
              })
              .catch(function (error) {})
          } else {
            message.error('First deploy this data source.')
          }
        }}
      >
        <CgDatabase />

        <span
          style={{
            fontSize: 13,
            marginLeft: 5,
          }}
        >
          Create Index
        </span>
      </div>
    </>
  )
}

export default CreateIndex
